/*
@mixin effects {
  // Kopiert da deprecated in Bootstrap seit 3.1.0
  // muss evtl. erweitert werden für ältere Browser

  // NOTE: Box-Shadow PS to CSS Converter: http://melanieceraso.com/psd-to-css3/
  .box-shadow(@shadow: 0 1px 3px rgba(0,0,0,.25)) {
    -webkit-box-shadow: @shadow; // iOS <4.3 & Android <4.1
    box-shadow: @shadow;
  }
  .rounded-corners (@radius: 5px) {
    border-radius: @radius;
    -webkit-border-radius: @radius;
    -moz-border-radius: @radius;
  }
}
*/

@mixin rotation($deg: 90deg) {
  /* Safari */
  -webkit-transform: rotate($deg);
  /* Firefox */
  -moz-transform: rotate($deg);
  /* IE10+ */
  transform: rotate($deg);
  /* Opera */
  -o-transform: rotate($deg);
  /* Internet Explorer */
  @IEdeg: round($deg / 90, 0);
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=$IEdeg);
}
