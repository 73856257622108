// Fonts
@import url(//fonts.googleapis.com/css?family=Nunito:400,300,700);
@import url(//fonts.googleapis.com/css?family=Droid+Sans:400,700);

// Externe Framework Files
@import '~bootstrap/scss/bootstrap';
@import '~bootstrap/scss/bootstrap-reboot';
@import '~bootstrap/scss/bootstrap-grid';

// Eigene scss Files (Variablen, Mixins, Struktur)
// NOTE: Namensgebung - Kollisionen mit Bootstrap Files vermeiden - Zusatz: custom-
@import "custom-variables.scss";
@import "custom-mixins.scss";

@import "lightbox.scss";
@import "sm.scss";

@import "main.scss";

