.sm {
  padding: 0;
  margin: 0;
  width: 100%;
  .sm-like {
    display: inline-block;
    position: relative;
    width: 100px;
    &.twitter {
      iframe {
        height: 20px;
      }
    }
    &.googleplus {
      iframe {
        height: 20px;
      }
    }
    &.facebook {
      width: 200px;
      iframe {
        height: 20px;
      }
    }
    &.pinterest {
      height: 20px;
      a {
        display: block;
        position: relative;
        top: -2px;
      }
    }
  }
}