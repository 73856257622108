html, body {
  background-color: $grey;
  width: 100%;
  min-height: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Droid Sans', sans-serif;
  font-size: 14px;
}
h2 {
  font-family: 'Nunito', sans-serif;
  font-weight: 700;
  font-size: 40px;
  text-transform: uppercase;
  color: $green;
  padding: 25px 0;
}
h3 {
  font-family: 'Nunito', sans-serif;
  font-weight: 500;
  font-size: 20px;
  color: $green;
  padding-bottom: 20px;
}
a {
  color: $green;
  text-decoration: none;
  outline: medium none;
  position: relative;
  &:hover {
    text-decoration: underline;
  }
}

.modal-backdrop {
  display: none;
  opacity: 0.3;
}

.modal-open .modal-backdrop {
  display: block;
}

.container {
  background-color: $white;
  padding: 0;
}

// NAVIGATION

#navigation {
  .container {
    padding: 0;
  }
  .navbar {
    font-family: 'Nunito', sans-serif;
    font-weight: 700;
    font-size: 10px;
    padding: 0;
    margin: 0;
    .container {
      padding: 5px 10px;
    }
    .navbar-collapse {
      padding: 0;
    }
    .navbar-nav {
      > li {
        > a {
          user-select: none;
          cursor: pointer;
          padding: 23px 10px 0 10px;
          margin: 0;
          outline: medium none !important;
          text-transform: uppercase;
          color: $dark-grey;
        }
      }
    }
  }
}

// Unternehmen

#company {
  position: relative;
  top: 0;
  left: 0;
  padding-top: 570px;
  &:before {
    position: absolute;
    margin: 0;
    padding: 0;
    top: 0;
    left: 0;
    background: $white url(../assets/bg-company.jpg) top 50px left 0 no-repeat;
    background-size: 100% auto;
    width: 100%;
    height: 570px;
    content: "";
  }
  .partner {
    padding: 60px 0 100px 0;
    img {
      max-width: 100%;
      height: 40px;
    }
  }
}

// Dienstleistungen

#service {
  position: relative;
  top: 0;
  left: 0;
  color: $white;
  background: $white url(../assets/bg-service.jpg) center center no-repeat;
  background-size: cover;
  h2 {
    color: $white;
    padding: 100px 0;
  }
  p {
    margin: 0;
    padding: 0;
  }
  hr {
    border-color: #eeeeee;
  }
  .service-points {
    padding-bottom: 40px;
  }
  .service-details {
    margin: 50px 0;
    height: 200px;
  }
  /* Effect 4: bottom border enlarge */
  a {
    display: inline-block;
    color: $white;
    font-size: 1em;
    font-weight: 500;
    letter-spacing: 1px;
    margin: 5px;
    outline: medium none;
    position: relative;
    text-decoration: none;
    text-shadow: 0 0 1px rgba(255, 255, 255, 0.3);
    padding: 10px;
    &::after {
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      height: 1px;
      background: #fff;
      content: '';
      opacity: 0;
      -webkit-transition: height 0.3s, opacity 0.3s, -webkit-transform 0.3s;
      -moz-transition: height 0.3s, opacity 0.3s, -moz-transform 0.3s;
      transition: height 0.3s, opacity 0.3s, transform 0.3s;
      -webkit-transform: translateY(-10px);
      -moz-transform: translateY(-10px);
      transform: translateY(-10px);
    }
    &:hover::after,
    &:focus::after {
      height: 1px;
      opacity: 1;
      -webkit-transform: translateY(0px);
      -moz-transform: translateY(0px);
      transform: translateY(0px);
    }
  }


}

// Referenzen
#references{
  h2 {
    padding: 55px 0 75px 0;
  }
  h3 {
    padding-bottom: 85px;
  }
  .img-circle {
    border-radius: 50%;
    width: 130px;
    height: 130px;
  }
  .ref-images {
    padding-bottom: 200px;
  }
  .control {
    padding-top: 42.5px;
    user-select: none;
  }
  a {
    outline: medium none;
  }
  .modal {
    .modal-dialog {
      max-width: 950px;
    }
    .modal-body {
      img {
        width: 100%;
        height: auto;
      }
    }
    .modal-footer {
      justify-content: flex-start;
    }
  }
}

// Team

#team {
  position: relative;
  top: 0;
  left: 0;
  color: $white;
  background: $white url(../assets/bg-team.jpg) center center no-repeat;
  background-size: cover;
  a {
    color: #cccccc;
    &:hover {
      color: #fff;
      text-decoration: none;
    }
  }
  h2 {
    padding-top: 100px;
    color: $white;
  }
  p {
    font-size: 12.8px;
    font-weight: bold;
    padding: 12px 0 8px 0;
    margin: 0;
    height: 60px;
  }
  .gf {
    height: auto !important;
  }
  .job-title {
    font-size: 11.8px;
    font-style: italic;
    padding-bottom: 12px;
  }
  .rotated {
    padding-top: 20px;
    white-space: nowrap;
    @include rotation(45deg);
  }
  .employees {
    height: 325px;
  }
}

// Kontakt
#contact {
  agm-map {
    height: 250px;
  }
  h2 {
    padding: 90px 0 80px 0;
  }
  input,
  textarea,
  .btn-md {
    border: 3px solid $light-grey;
    border-radius: 0 !important;
    margin-bottom: 40px !important;
    -webkit-appearance: none;
    &:required:invalid, input:focus:invalid {
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAeVJREFUeNqkU01oE1EQ/mazSTdRmqSxLVSJVKU9RYoHD8WfHr16kh5EFA8eSy6hXrwUPBSKZ6E9V1CU4tGf0DZWDEQrGkhprRDbCvlpavan3ezu+LLSUnADLZnHwHvzmJlvvpkhZkY7IqFNaTuAfPhhP/8Uo87SGSaDsP27hgYM/lUpy6lHdqsAtM+BPfvqKp3ufYKwcgmWCug6oKmrrG3PoaqngWjdd/922hOBs5C/jJA6x7AiUt8VYVUAVQXXShfIqCYRMZO8/N1N+B8H1sOUwivpSUSVCJ2MAjtVwBAIdv+AQkHQqbOgc+fBvorjyQENDcch16/BtkQdAlC4E6jrYHGgGU18Io3gmhzJuwub6/fQJYNi/YBpCifhbDaAPXFvCBVxXbvfbNGFeN8DkjogWAd8DljV3KRutcEAeHMN/HXZ4p9bhncJHCyhNx52R0Kv/XNuQvYBnM+CP7xddXL5KaJw0TMAF8qjnMvegeK/SLHubhpKDKIrJDlvXoMX3y9xcSMZyBQ+tpyk5hzsa2Ns7LGdfWdbL6fZvHn92d7dgROH/730YBLtiZmEdGPkFnhX4kxmjVe2xgPfCtrRd6GHRtEh9zsL8xVe+pwSzj+OtwvletZZ/wLeKD71L+ZeHHWZ/gowABkp7AwwnEjFAAAAAElFTkSuQmCC);
      background-position: right 6px top 6px;
      background-repeat: no-repeat;
      -moz-box-shadow: none;
    }
    &:required:valid {
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAepJREFUeNrEk79PFEEUx9/uDDd7v/AAQQnEQokmJCRGwc7/QeM/YGVxsZJQYI/EhCChICYmUJigNBSGzobQaI5SaYRw6imne0d2D/bYmZ3dGd+YQKEHYiyc5GUyb3Y+77vfeWNpreFfhvXfAWAAJtbKi7dff1rWK9vPHx3mThP2Iaipk5EzTg8Qmru38H7izmkFHAF4WH1R52654PR0Oamzj2dKxYt/Bbg1OPZuY3d9aU82VGem/5LtnJscLxWzfzRxaWNqWJP0XUadIbSzu5DuvUJpzq7sfYBKsP1GJeLB+PWpt8cCXm4+2+zLXx4guKiLXWA2Nc5ChOuacMEPv20FkT+dIawyenVi5VcAbcigWzXLeNiDRCdwId0LFm5IUMBIBgrp8wOEsFlfeCGm23/zoBZWn9a4C314A1nCoM1OAVccuGyCkPs/P+pIdVIOkG9pIh6YlyqCrwhRKD3GygK9PUBImIQQxRi4b2O+JcCLg8+e8NZiLVEygwCrWpYF0jQJziYU/ho2TUuCPTn8hHcQNuZy1/94sAMOzQHDeqaij7Cd8Dt8CatGhX3iWxgtFW/m29pnUjR7TSQcRCIAVW1FSr6KAVYdi+5Pj8yunviYHq7f72po3Y9dbi7CxzDO1+duzCXH9cEPAQYAhJELY/AqBtwAAAAASUVORK5CYII=);
      background-position: right 6px top 6px;
      background-repeat: no-repeat;
    }
  }
  input {
    height: 48px;
  }
  textarea {
    resize: none;
    height: 200px;
  }
  address {
    font-weight: bold;
    color: $medium-grey;
    font-size: 17px;
    padding-bottom: 40px;
  }
  .btn-md {
    text-transform: uppercase;
    border-color: $green;
    background-color: $white;
    color: $green;
    font-weight: bold;
  }
  form {
    padding-bottom: 60px;
  }
  .error {
    color: $red;
  }
  .success {
    color: $green;
  }
}

// jobs

#jobs {
  position: relative;
  top: 0;
  left: 0;
  color: $white;
  background: $white url(../assets/bg-jobs.jpg) center center no-repeat;
  background-size: cover;
  padding-bottom: 100px;
  h2, h3 {
    color: $white;
  }
  h3 {
    margin-top: 15px;
  }
  h2 {
    padding: 100px 0 60px 0;
  }
  a {
    color: $white;
  }
  ul {
    margin-bottom: 20px;
  }
}

// Footer

#footer {
  background-color: $darkest-grey;
  color: $lighter-grey;
  padding: 60px 0;
  a {
    color: $white;
    &:hover {
      color: $light-grey;
    }
  }
}

#privacy {
  &.modal {
    .modal-dialog {
      max-width: 950px;

    }
    .modal-body {
      max-height: 500px;
      overflow: auto;
    }
  }
}

#cookie {
  margin-bottom: 0;
  z-index: 1041;
}

// XS Devices
// Smartphones

@include media-breakpoint-down(sm) {
  html, body {
   font-size: 12px;
  }
  h2 {
    padding: 170px 0 30px 0 !important;
    font-size: 22px;
  }
  .container {
    padding: 0;
  }
  #company {
    padding-top: 100px;
  }
  #footer {
    padding: 15px;
  }
  form {
    padding: 15px;
  }
}
